import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import G2Logo from "@/assets/g2.svg?react";

export default function G2Badge() {
  return (
    <Card className="overflow-visible rounded-xl px-4 pb-5 text-center">
      <div className="-mb-4 inline-block size-16 -translate-y-5 rounded-full border-4 border-white bg-[#FF492C] text-white shadow-md">
        <G2Logo />
      </div>
      <h4 className="mb-1 font-bold">Help us spread the word!</h4>
      <p className="mb-3 text-sm leading-tight">
        Enjoying Avail? We want to share your feedback with the world.
      </p>
      <Button asChild className="bg-[#FF492C] px-10 hover:bg-[#5A39A2]">
        <a
          href="https://www.g2.com/products/avail-swag-management-platform/take_survey"
          target="_blank"
        >
          Leave a review
        </a>
      </Button>
    </Card>
  );
}
